import React from 'react';
import Layout from '../../components/Layout';

const Index = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Resources</h1>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
